<template>
    <div class="wrap">
        <div class="back">
            <a :href="furyuTopUrl" target="_blank" @click="ga4ClickEvent(furyuTopUrl, furyuLogoAlt)"><img class="furyu-logo" :src="buildImgUrl('logo_furyu.png')" :alt="furyuLogoAlt"></a>
        </div>
        <div class="contents">
            <div class="contents-top">
                <img class="img-main" :src="buildImgUrl('gtl938_gtl529_limit2303_pc_top.png')" alt="プリ画取得サービス No.1">
                <img class="img-logo-copy" :src="buildImgUrl('img_logo_copy.png')" alt="フリューのプリントシール機のプリを保存できる！">
                <p class="caution text-comments is-text-center"><span class="caution-font">※</span>PC・タブレットからはご利用いただけません。</p>
                <div class="qr-wrap text-default is-text-center">
                    <div>
                        <p class="qr-sp">スマートフォン用サイトはこちら</p>
                        <img class="qr-img" :src="buildImgUrl('qr/qr_sp_pict.png')" alt="スマートフォン用サイトQRコード">
                    </div>
                    <div class="v-line"></div>
                    <div>
                        <p class="is-mgb8">スマートフォン用アプリはこちら</p>
                        <div class="qr-app">
                            <div>
                                <a :href="appStoreUrl" target="_blank" @click="ga4ClickEvent(appStoreUrl, appStoreAlt)"><div class="is-mgb16"><img class="btn-img-ios" :src="iosBtnImgUrl" alt="App Storeからダウンロード"></div></a>
                                <img class="qr-img" :src="buildImgUrl('qr/qr_app_pic_ios.png')" :alt="appStoreAlt">
                            </div>
                            <div>
                                <a :href="googlePlayUrl" target="_blank" @click="ga4ClickEvent(googlePlayUrl, googlePlayAlt)"><div class="is-mgb16"><img class="btn-img-and" :src="androidBtnImgUrl" alt="Google Playで手に入れよう"></div></a>
                                <img class="qr-img" :src="buildImgUrl('qr/qr_app_pic_and.png')" :alt="googlePlayAlt">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contents-sub">
                <div class="info text-default">
                    <ul>
                        <li>「ピクトリンク」にアクセスするときは、iPhoneの標準ブラウザ(Safari)か、Android™端末の標準ブラウザまたはChromeでアクセスしてください。</li>
                        <li>iPadは動作保証対象外です。またPC・iPad以外のタブレット端末からのアクセスは非対応です。</li>
                        <li>有料登録の解除をご希望でログインできない場合は<link-text :link="withdrawalForGuestUrl" text="こちら"/></li>
                    </ul>
                </div>
                <p class="text-comments is-mgb24 is-text-center">フリューが運営するその他のサイト　カラコン通販 <link-text :link="mewshopUrl" text="Mew contact" target="_blank"/></p>
            </div>
        </div>
        <div class="footer is-text-center">
            <div class="footer-contents">
                <p class="is-mgb20"><span class="is-mgr20"><link-text :link="furyuTopUrl" text="運営会社" target="_blank"/></span><span class="is-mgr20"><link-text :link="privacyPolicyUrl" text="プライバシーポリシー"/></span><link-text :link="mailAdd" text="お問い合わせ"/></p>
                <p class="is-mgb20">
                    Apple、Apple ロゴ、iPad、iPhone は米国および他の国々で登録された Apple Inc. の商標です。<br>
                    iPhoneはアイホン株式会社の登録商標であり、Apple Inc.がライセンスに基づき使用しています。App Store は Apple Inc. のサービスマークです。<br>
                    Android、Google Play、Google Play ロゴは、Google Inc. の商標です。QRコードは株式会社デンソーウェーブの登録商標です。
                </p>
                <p>© FURYU Corporation. All Rights Reserved.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import * as urlUtil from '../../util/UrlUtil'
    import {getClickAllEvent} from "../../util/Ga4EventUtil";
    import LinkText from "../atoms/LinkText.vue";

    export default {
        name: "PcTop",
        components: {
            LinkText
        },
        data() {
            return {
                furyuTopUrl: urlUtil.productUrlUtil.furyuUrl(),
                appStoreUrl: urlUtil.pictlinkAppSchemeUrlUtil.appStoreUrl(),
                googlePlayUrl: urlUtil.pictlinkAppSchemeUrlUtil.googlePlayUrl(),
                withdrawalForGuestUrl: urlUtil.servletUrlUtil.withdrawalForGuestUrl(),
                mewshopUrl: urlUtil.productUrlUtil.mewcontactUrl(),
                haru2Url: urlUtil.productUrlUtil.haru2Url(),
                privacyPolicyUrl: urlUtil.policyUrlUtil.privacyPolicyUrl(),
                mailAdd: urlUtil.mailAddUtil.supportMail(),
                iosBtnImgUrl: urlUtil.imageUrlUtil.AppImageUrl("ios"),
                androidBtnImgUrl: urlUtil.imageUrlUtil.AppImageUrl("android"),
                furyuLogoAlt: 'フリューlogo',
                appStoreAlt: 'iOS用アプリQRコード',
                googlePlayAlt: 'Android用アプリQRコード'
            }
        },
        methods: {
            ga4ClickEvent(elUrl, elAlt) {
                getClickAllEvent(elUrl, elAlt)
            },
            /**
             * 画像のURLを組み立てる
             * @param {string} imgName 画像名
             */
            buildImgUrl(imgName) {
                return urlUtil.imageUrlUtil.buildS3ImageUrl('img/pc/' + imgName)
            },
        }
    }
</script>
