/**
 * gtagイベントラベル送信の関数を渡す
 *
 * storybookでgtagが使用できず
 * storybookではmockを見るようutilにgtagを置いています
 * 使用コンポーネント：Meerkat, Modal
 */
export function getGa4EventLabel(eventName, eventLabel) {
    gtag('event', eventName, {
        'eventLabel': eventLabel
    })
}

/**
 * GA4にviewイベントを送信する（routeオブジェクトのpathプロパティを渡す）
 * @param {string} routePath
 */
export function sendViewEvent(routePath) {
    const pageUrl = getPageUrl(routePath)
    gtag('event', 'view', {
        'pageUrl': pageUrl
    });
}

/**
 * GA4にviewイベントを送信するためのURLを組み立てる（routeオブジェクトのpathプロパティを渡す）
 * @param {string} routePath
 */
export function getPageUrl(routePath) {
    const allSearch = getAllSearch()
    return window.location.pathname + '#' + routePath + allSearch
}

/**
* 筐体情報を送信する
*/
export function sendMachineIdentifier(machineIdentifier) {
    gtag('event', 'view', {
        'machineIdentifier': machineIdentifier,
    });
}

/**
 * click_allイベントを送信するため全aタグに適用
 */
export function getClickAllEvent(elUrl, elText) {
    let getHref = window.location.href
    let pageUrl = getHref.substring(getHref.indexOf('/sp/'), getHref.length)
    /**
     * エンコード対応されているパスをGA送信前にデコード
     * （エンコード済みパスだけでなく全elUrlがdecodeURIComponent対象になっています）
     */
    let decodeElUrl = decodeURIComponent(elUrl)
    gtag('event', 'click_all', {
        'pageUrl': pageUrl,
        'elementUrl': decodeElUrl,
        'elementText': elText
    })
}

/**
 * click_allイベントを送信するため全aタグに適用(A/Bテスト用)
 */
export function getClickAllEventAB(elUrl, elText, ab) {
    let getHref = window.location.href
    let pageUrl = getHref.substring(getHref.indexOf('/sp/'), getHref.length)
    /**
     * エンコード対応されているパスをGA送信前にデコード
     * （エンコード済みパスだけでなく全elUrlがdecodeURIComponent対象になっています）
     */
    let decodeElUrl = decodeURIComponent(elUrl)
    gtag('event', 'click_all', {
        'pageUrl': pageUrl,
        'elementUrl': decodeElUrl,
        'elementText': elText,
        'ab': ab
    })
}

/**
 * スクロール深度を送信するための関数
 * 使用コンポーネント：PuriNewsDetail
 */

export function getScrollEvent() {
    /* スクロールポジション初期化 */
    let initialPosition = window.scrollY;
    const getHref = window.location.href
    const pageUrl = getHref.substring(getHref.indexOf('/sp/'), getHref.length)

    const setGa4Event = (depthThreshold) => {
        gtag('event', 'scroll_depth', {
            'pageUrl': pageUrl,
            'scrollDepthThreshold': depthThreshold + '%'
        })
    }

    /* 次にログ送信したいスクロール深度の％数 */
    let nextLog = 25

    /* ビューポート */
    const viewPort = document.documentElement.clientHeight;

    /**
     * FIXME 今後使用箇所が増える場合はremoveEventListenerできる形に修正する必要あり
     */
    document.addEventListener('scroll', function() {
        /* コンテンツ高さ、スクロールポジション */
        const scrollPosition = window.scrollY;
        const scrollHeight = document.documentElement.scrollHeight;

        /* 下向きスクロールのみにスクロール％を計算、ゴールを発火する */
        if (scrollPosition > initialPosition) {
            /* スクロール％計算 */
            const percent = Math.floor(scrollPosition / (scrollHeight - viewPort) * 100);
            /* スクロール％を25刻み（切り下げ）に変換（スクロールが早すぎると25%刻みの数値が取れないことがあるため） */
            const parcent25Increments = Math.floor(percent/25)*25;
            /* 25刻み％がログ送信したいスクロール深度の％数と同じならログ送信 */
            if(parcent25Increments === nextLog){
                setGa4Event(nextLog)
                /* 次にログ送信したいスクロール深度の％数を更新 */
                nextLog = nextLog + 25
            }
            initialPosition = scrollPosition;
        }
    }, false);
}

/**
 * パラメータを組み立てる関数
 * （vmでaddQueryDataで組み立てている処理をvueで再現する）
 *
 * TODO:このutilがこのファイルにあるのが適切か要検討
 */
export function addQueryData(key, value) {
    return '?' + key + '=' + value
}

/**
 * パラメータを合体させる関数（ハッシュ前パラメ+ハッシュ後パラメの順番で合体）
 * URLにハッシュを使用する場合、正しいのはハッシュ前にのみパラメータが付くパターンだが
 * ハッシュ後に付けるのが一般的なため、誤ってどちらにもパラメータを付けてしまう場合があるので
 * イベント送信で扱いやすいようハッシュ前後のパラメータを合体させた文字列を返す
 * ※流入元のfromパラメータは重要なので、fromがある場合は先頭に持ってくる
 * 例）/sp/jhs_free?hash=hash#/lp?param1=1&from=instagram → ?from=instagram&param1=1&hash=hash
 *
 * @return {string}
 */
export function getAllSearch() {
    // ハッシュ後パラメータを取得（#/123/?test=hoge → test=hoge）
    const missSearch = window.location.hash.split('?')[1];

    // ハッシュ前パラメータがあった場合、"ハッシュ前 + ハッシュ後"の順番で合体させる
    let allSearch = ''
    if(window.location.search){
        if(missSearch){ // ハッシュ前パラメ有 ＆ ハッシュ後パラメ有
            allSearch = window.location.search + '&' + missSearch
        } else { // ハッシュ前パラメ有 ＆ ハッシュ後パラメ無
            allSearch = window.location.search
        }
    } else {
        if(missSearch){ // ハッシュ前パラメ無 ＆ ハッシュ後パラメ有
            allSearch = '?' + missSearch
        } else { // ハッシュ前パラメ無 ＆ ハッシュ後パラメ無
            allSearch = ''
        }
    }
    // from=〇〇 & URLの末尾のパラメータ & #の前にあったパラメータ の順番に並び替え
    const allSearchString = allSearch.substring(1) // 先頭の「?」を取る
    let sortedAllSearch = ''
    if (allSearchString) {
        const paramList = allSearchString.split('&')
        // 文字列先頭一致でfromパラメータのindexを検索
        const fromParamIndex = paramList.findIndex(param => param.indexOf('from=') === 0)
        // fromパラメータがparamList[1]以降に存在していたら、paramList[0]に持ってくる
        if(fromParamIndex > 0){
            paramList.unshift(paramList[fromParamIndex])
            paramList.splice(fromParamIndex + 1, 1)
        }
        sortedAllSearch = '?' + paramList.join('&')
    }
    return sortedAllSearch
}
