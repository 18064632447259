<template>
    <a :href="link"
        :target="newWindow ? '_blank' : '_self'"
        class="m-text-link"
        data-jest="active-btn"
        @click="ga4ClickEvent"
    >{{ text || link }}</a>
    <!--マウント時にテキスト前後に半角スペースが入らないよう、aタグとtextの間は改行しないようにしています-->
</template>

<script>
import {getClickAllEvent} from "../../util/Ga4EventUtil";

export default {
    name: "LinkText",
    props: {
        /**
         * 遷移先URL
         */
        link: {
            type: String,
            required: false
        },
        /**
         * テキスト
         */
        text: {
            type: String,
            required: false
        },
        /**
         * 別タブでの遷移かどうか
         */
        newWindow: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        ga4ClickEvent() {
            getClickAllEvent(this.link, this.text)
        }
    }
}
</script>
