
/**
 * APIのエンドポイントを組み立てるクラス
 */
export default class ApiEndpointUrlUtil {

    /**
     * ユーザのプロフィール情報を取得するAPIのエンドポイント
     */
    userProfileApiUrl = () => '/sp/profile/summary';

    /**
     * ユーザのプロフィール作成、更新APIのエンドポイント
     */
    updateProfileApiUrl = () => '/sp/profile';

    /**
     * ユーザのプロフィール情報を取得するAPIのエンドポイント
     */
    getProfileApiUrl = () => '/sp/profile';

    /**
     * ユーザのシステム情報を取得するAPI(v2)のエンドポイント
     */
    accountV2ApiUrl = () => '/sp/2/account/self';

    /**
     * お試し情報を取得するAPIのエンドポイント
     */
    paymentTrialApiUrl = () => '/sp/2/payment/trial';

    /**
     * 課金情報を取得するAPIのエンドポイント
     */
    paymentApiUrl = () => '/sp/2/payment/self';

    /**
     * ユーザのアクセス状況を取得するAPIのエンドポイント
     */
    accessClientApiUrl = () => '/sp/2/user/access';

    /**
     * ユーザ導線最適化の出し分け条件を取得するAPIのエンドポイント
     * @param placeId {String} 出し分けを行いたい場所に割り当てられたidentifier.
     */
    optimizedFlowApiUrl = (placeId) => `/sp/2/optimized_flow/${placeId}`;

    /**
     * ユーザが所持している画像の一覧を取得するAPIのエンドポイント
     *
     * @param {string} category 画像のカテゴリ
     * @param {string} order 並び順
     * @param {number} page 何ページ目かを表す数字
     * @param {number} count 1ページあたりの画像数
     * @param {number} [limit=0] 1筐体あたりの画像数(筐体別のみ)
     */
    myPageMediaListApiUrl = (category, order, page, count, limit = 0) => {
        let url = `/sp/2/images/${order}/${category}/list.json?page=${page}&count=${count}`;
        if(limit > 0) {
            url += `&limit=${limit}`
        }
        return url
    };

    /**
     * ユーザが所持している動画の一覧を取得するAPIのエンドポイント
     *
     * @param page {number} 何ページ目かを表す数字
     * @param count {number} 1ページあたりの画像数
     */
    myPageMovieListApiUrl = (page, count) => `/sp/2/purimovies/self?page=${page}&count=${count}`;

    /**
     * ユーザが所持している任意の動画をを取得するAPIのエンドポイント
     *
     * @param movieId {number}
     */
    myPageMovieApiUrl = (movieId) => `/sp/2/purimovies/${movieId}`;

    /**
     * ユーザが所持している筐体別の画像の一覧を取得するAPIのエンドポイント
     *
     * @param {string} category 画像のカテゴリ
     * @param {number} machineId 筐体ID
     * @param {number} page 何ページ目かを表す数字
     * @param {number} limit 1ページあたりの画像数
     */
    myPageMachineMediaListApiUrl = (category, machineId, page, limit) => `/sp/2/images/machine/${category}/${machineId}/list.json?page=${page}&count=${limit}`;

    /**
     * ログインアカウントに紐づく未保存画像の最新n件取得するAPIのエンドポイント
     *
     * @param {number} count 取得する枚数
     * @param {boolean} isIncludePuri らくがきありの未保存を含めるか
     * @param {boolean} isIncludeRaw らくがきなしの未保存を含めるか
     */
    unsavedPuriApiUrl = (count, isIncludePuri, isIncludeRaw) => `/sp/2/images/unsaved_only/list.json?count=${count}&puri=${Number(isIncludePuri)}&raw=${Number(isIncludeRaw)}`;

    /**
     * 指定されたsealIdに紐づく未保存プリを取得するAPIのエンドポイント
     *
     * @param {String} sealId シールID
     * @param {boolean} isIncludePuri らくがきありの未保存を含めるか
     * @param {boolean} isIncludeRaw らくがきなしの未保存を含めるか
     */
    unsavedSealPuriApiUrl = (sealId, isIncludePuri, isIncludeRaw) => `/sp/2/images/unsaved/${sealId}?puri=${isIncludePuri}&raw=${isIncludeRaw}`;

    /**
     * 指定されたシールIDに紐づくプリを取得するAPI(V2)のエンドポイント
     *
     * @param {String} sealId シールID
     */
    puriSealsApiUrl = (sealId) => `/sp/2/seals/${sealId}`;

    /**
     * 広告枠であるspaceIdに掲載されているバナー情報を返すAPIのエンドポイント
     * @param {string} spaceId バナーを掲載する広告枠(場所)を示すID
     */
    furyuBannerApiUrl = (spaceId) => `/sp/2/banner/space_id/${spaceId}`;

    /**
     * DynamicLinksのURLを返すAPIのエンドポイント
     */
    dynamicLinkApiUrl = () => `/sp/2/dynamic_links`;

    /**
     * ログインバリデーションの結果・ログイン成功時に遷移先URLを返すAPIのエンドポイント
     */
    loginApiUrl = () => `/sp//ajax/login/`;

    /**
     * ログイン後の遷移先を指定するためのAPIのエンドポイント
     */
    setAfterLoginUrlApiUrl = () => `/sp/2/cookie/afterLogin`;

    /**
     * アカウント作成後の遷移先を指定するためのAPIのエンドポイント
     */
    setAfterRegisterUrlApiUrl = () => `/sp/2/cookie/afterRegister`;

    /**
     * 最新のプライバシーポリシー同意状態を取得するAPI
     * https://voyager-docs.s3.ap-northeast-1.amazonaws.com/sp/openapi/epic/PICTLINK-6631_privacy_policy/index.html#tag/PrivacyPolicy/paths/~12~1users~1self~1privacy_policy_statuses~1latest/get
     */
    privacyPolicyStatusesLatestApiUrl = () => '/sp/2/users/self/privacy_policy_statuses/latest';

    /**
     * プライバシーポリシーに同意するAPIのエンドポイント
     * https://voyager-docs.s3.ap-northeast-1.amazonaws.com/sp/openapi/epic/PICTLINK-6631_privacy_policy/index.html#tag/PrivacyPolicy/paths/~12~1users~1self~1privacy_policy_statuses/post
     */
    setPrivacyPolicyAgreementApiUrl = () => '/sp/2/users/self/privacy_policy_statuses';

    /**
     * アクセスユーザの購読解除可能な決済リストを取得するAPIのエンドポイント
     * アプリ課金であっても（サイト上からは退会できないが）返却する。
     * voyager-sp/docs/build/swagger-ui-voyagerSp/index.html#/Payment/get_2_payment_unsubscribable
     */
    paymentUnsubscribableApiUrl = () => '/sp/2/payment/unsubscribable'

    /**
     * ユーザへのお知らせAPIのエンドポイント
     * @param {String} categoryName お知らせカテゴリ名
     * https://voyager-docs.s3.ap-northeast-1.amazonaws.com/sp/openapi/feature/PICTLINK-10134_audition_result_openapi/index.html#tag/PrivacyPolicy/paths/~12~1users~1self~1privacy_policy_statuses/post
     */
    newsInfoApiUrl = (categoryName) => `/sp/2/news/self/info?newsCategoryName=${categoryName}`

    /**
     * 認証コードをメールで送信するAPI
     */
    sendRegisterAccountVerificationMailApiUrl = () => '/sp/2/api/account/mail_address'

    /**
     * ピクトリンクアカウントを作成するためのメアドの認証コードを受け付けるAPI
     */
    verifyRegisterAccountCodeFromMailApiUrl = () => '/sp/2/api/account/mail_address/verification'

    /**
     * 広告(Pictlink Ad)情報取得APIのエンドポイント
     * @param {String} spaceId 広告枠の識別子
     * https://voyager-docs.s3.ap-northeast-1.amazonaws.com/sp/openapi/feature/PICTLINK-11869_bridge_pictlink-ad_docs/index.html#tag/Advertisement
     */
    getPictlinkAdApiUrl = (spaceId) => `/sp/advertisements/self?spaceId=${spaceId}`

    /**
     * 広告(Pictlink Ad)反応計測APIのエンドポイント
     * @param {String} advertisementId 広告枠の識別子
     * https://voyager-docs.s3.ap-northeast-1.amazonaws.com/sp/openapi/feature/PICTLINK-11869_bridge_pictlink-ad_docs/index.html#tag/Advertisement
     */
    reactPictlinkAdApiUrl = (advertisementId) => `/sp/advertisements/${advertisementId}/reactions`

    getProfileValidateApiUrl = () => "/sp/2/account/registration/line/validate_parameters";

    registerAccountEmailApiUrl = () => "/sp/account/send_register_mail_from_form";

    validateRegisterAccountEmailApiUrl = () => "/sp/account/validate_email_parameters";

    registerAccountWithPasswordApiUrl = () => '/sp/2/api/account/';

    mailAddressVerificationStartApiUrl = ()  => '/sp/2/api/email_verifications/';

    mailAddressVerificationApiUrl = (sessionId) => `/sp/2/api/email_verifications/${sessionId}/verified/`;

    mailAddressWithPasswordRegistrationApiUrl = () => '/sp/2/api/account/mail_address/registration'
}
