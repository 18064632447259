import configs from 'configs';
import Path from 'path';

/**
 * ドメインを含めた絶対URLを生成するためのclass
 */
export default class AbsoluteUrlUtil {
    static CONTEXT_PATH = '/sp';
    /**
     * 指定されたpathでピクトリンクの絶対URLを生成する
     * ※ただしプロトコルは含まないので注意 (http://の部分がない)
     *
     * @path /spから始まる、ピクトリンクエンドポイントの相対パス
     * @return {String} エンコード済みのURL
     */
    buildPictlinkAbsoluteUrl = (path) => {
        const host = window.location.host;
        return encodeURIComponent(host + path)
    };
    /**
     * 指定されたパスにコンテキストパスを追加し絶対URLを生成する
     * プロトコルは含む
     * @param path /spを含まないピクトリンクエンドポイントの相対パス
     * @param baseUrl ベースとなるhostとプロトコルを含むURL 通常はwindow.location.hrefで取得
     * @return {string|null} baseUrlのホストにコンテキストパスspと指定されたパスを追加した絶対URL、baseUrlが無効の場合はnull
     */
    buildPictlinkAbsoluteUrlWithContextPath = (path, baseUrl) => {
        try{
            const completePath = Path.join(AbsoluteUrlUtil.CONTEXT_PATH, path??'');
            return new URL(completePath, baseUrl).toString();
        }catch (error){
            console.error(`path:${path},baseUrl:${baseUrl}のパースに失敗しました`, error);
            return null;
        }
    }

    /**
     * 指定されたpathでpictlink-authの絶対URLを生成する
     *
     * @param path /から始まる、pictlink-authエンドポイントの相対パス
     * @returns {string} URL文字列
     */
    buildPictlinkAuth = (path) => {
        return `${configs.pictlinkAuthPath}${path}`
    }
}
