/**
 * SNS関連のURLを組み立てるクラス
 */
export default class SnsUrlUtil {

    /**
     * 公式TwitterへのURL
     */
    officialTwitterUrl = () => `https://twitter.com/furyu_puri`;

    /**
     * 公式InstagramへのURL
     */
    officialInstagramUrl = () => `https://www.instagram.com/furyu_girlslabo/`;

    /**
     * 公式LINEへのURL
     */
    officialLineUrl = () => `https://lin.ee/U5vJZ9y`;

    /**
     * 公式TicTokへのURL
     */
    officialTikTokUrl = () => `https://www.tiktok.com/@pictlink_furyu?_t=8lQUcGuKK1P&_r=1`;
}