import configs from 'configs';

/**
 * 画像のURLを組み立てるクラス.
 */
export default class ImageUrlUtil {

    buildS3ImageUrl = (key) => {
        const _key = key.startsWith('/') ? key : '/' + key;
        return `${configs.cloudFrontPath}${_key}`
    };

    /**
     * 保存済みプリの画像urlを組み立てる.
     *
     * @param {String} type 画像サイズのパターン
     * @param {Number} imageId プリの画像ID
     */
    puriThumbUrl = (type, imageId) => {
        let url = `/sp/2/mypage/image/${imageId}`;
        switch (type) {
            case 'middle':
                url += '_thumb';
                break;
            case 'qvga':
                url += '_qthumb';
                break;
            default:
                break
        }
        url += ".jpg";
        return url;
    };

    /**
     * 未保存プリの画像urlを組み立てる.
     *
     * @param {Number} sealId      未保存プリのシールID
     * @param {Number} number      当該未保存プリのプリ番号
     * @param {Number} [padding=0] 画像に含める余白(指定しない場合は余白なし)
     */
    unsavedPuriThumbUrl = (sealId, number, padding = 0) => `/sp/puri/thum.jpg?sealId=${sealId}&number=${number}&padding=${padding}`;

    /**
     * 筐体のサムネイル画像(S3管理)urlを組み立てる.
     *
     * @param {Number} machineId 筐体ID
     */
    machineThumbUrl = (machineId) => `${configs.cloudFrontPath}/machine/icon/machine_ID${machineId}.png`;


    /**
     * meerkatバナー全般のパス
     * S3管理
     *
     * @param {string} imgName 画像名
     */
    meerkatS3ImageUrl = (imgName) => `${configs.cloudFrontPath}/banner/meerkat/${imgName}`;

    /**
     * 画像の長押し保存を禁止するためのガード画像のURL
     */
    copyGuardImageUrl = () => `/img/guard.gif`;

    /**
     * 画像のLoading画像のURL
     */
    loadingImageUrl = () => `/img/loading-white-s.gif`;

    /**
     * プリ読み込みエラー画像のURL
     */
    notFoundPuriImageUrl = () => `/img/not_found_puri.png`;

    /**
     * リロードアイコン画像のURL
     */
    reloadImageUrl = () => `/img/icon_reload.png`;

    /**
     * お試し訴求画像
     */
    trialAppealImageUrl = () => `/img/banner/banner_trial.jpg`;

    /**
     * AppStoreボタン画像
     */
    AppImageUrl = (os) => {
        if(os === "ios") {
            os = "app_store.svg"
        } else if ("android") {
            os = "google-play.png"
        }
        return `/img/launch_app/${os}`;
    };

    /**
     * 筐体Meerkatバナー(pngのみ)
     * S3管理
     */
    meerkatMachineImageUrl = (machineId) => `${configs.cloudFrontPath}/banner/meerkat/bnr_${machineId}.png`;

    /**
     * BannerCampaignのパス
     * S3管理
     *
     * @param {string} imgName 画像名
     */
    puriBannerS3ImageUrl = (imgName) => `${configs.cloudFrontPath}/banner/campaign/${imgName}.png`;

    /**
     * OGP画像,TwitterCardのパス
     * S3管理
     *
     * @param {string} imgName 画像名
     */
    ogpS3ImageUrl = (imgName) => `${configs.cloudFrontPath}/banner/ogp/${imgName}`;

    /**
     * topics画像のパス
     * S3管理
     *
     * @param {string} imgName 画像名
     */
    topicsS3ImageUrl = (imgName) => `${configs.cloudFrontPath}/banner/icon/${imgName}`;

    /**
     * アプリ訴求用画像のパス
     * S3管理
     *
     * @param {string} imgName 画像名
     */
    appliAppealS3ImageUrl = (imgName) => `${configs.cloudFrontPath}/banner/appli/${imgName}`;

    /**
     * 筐体紹介ページ画像のパス
     * S3管理
     * @param {string} machineId 筐体ID
     * @param {string} imgName 画像名
     */
    machineIntroductionImageUrl = (machineId, imgName) => `${configs.cloudFrontPath}/machine/${machineId}/${imgName}`;

    /**
     * 筐体紹介ページ共通画像のパス
     * S3管理
     * @param {string} imgName 画像名
     */
    machineIntroductionCommonImageUrl = (imgName) => `${configs.cloudFrontPath}/machine/${imgName}`;

    /**
     * ヘルプ詳細ページ画像のパス
     * S3管理
     * @param {string} imgName 画像名
     */
    helpImageUrl = (imgName) => `${configs.cloudFrontPath}/help/image/${imgName}`;

}
