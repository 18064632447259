export default class MailAddUtil {

    /**
     * お問い合わせアドレス
     */
    supportAdd = () => `support-sp-pictlink@furyu.jp`;

    /**
     * お問い合わせメーラーリンク
     */
    supportMail = () => `mailto:${this.supportAdd()}`;

    /**
     * アプリお問い合わせアドレス
     */
    appSupportAdd = () => `support-appli-pictlink@furyu.jp`;

    /**
     * アプリお問い合わせメーラーリンク
     */
    appSupportMail = () => `mailto:${this.appSupportAdd()}`;
}