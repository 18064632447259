import ServletUrlUtil from "./ServletUrlUtil";

export default class WithdrawalUrlUtil extends ServletUrlUtil {

    plusWithdrawalUrl = (paymentMethod) => {
        switch (paymentMethod) {
            case "creditCard":
                return this.veritransPremiumWithdrawalUrl();
            case "docomoPayment":
                return this.docomoPaymentPremiumWithdrawalUrl();
            case "auOne":
                return this.auPaymentPremiumWithdrawalUrl();
            case "spMode":
                return this.spmodePremiumWithdrawalUrl();
            case "softbankPayment":
                return this.softBankAuthUrl('/sp/2/payment/softbank/addon/withdraw/pictlinkauth', '/');
            default:
                return "";
        }
    }

    standardWithdrawalUrl = (paymentMethod) => {
        switch (paymentMethod) {
            case "creditCard":
                return this.veritransStandardWithdrawalUrl();
            case "docomoPayment":
                return this.docomoPaymentStandardWithdrawalUrl();
            case "auOne":
                return this.auPaymentStandardWithdrawalUrl();
            case "auOneShift":
                return this.auPaymentShiftStandardWithdrawalUrl();
            case "spMode":
                return this.spmodeStandardWithdrawalUrl();
            case "spModeShift":
                return this.spmodeShiftStandardWithdrawalUrl();
            case "softbankPayment":
                return this.softBankAuthUrl('/sp/softbankwithdrawal/withdrawal/pictlinkauth', '/');
            default:
                return "";
        }
    }
}
