/**
 * 規約系ページのURLを組み立てるクラス
 */
export default class PolicyUrlUtil {

    /**
     * 利用規約ページへのURLを返す.
     */
    termsOfServiceUrl = () => `/sp/common/information/terms_a`;

    /**
     * プライバシーポリシーへのURLを返す.
     */
    privacyPolicyUrl = () => `/sp/common/information/appliPolicy`;

    /**
     * FURYUのプライバシーポリシーへのURLを返す.
     */
    furyuPrivacyPolicyUrl = () => `https://www.furyu.jp/service_privacy.html`;

    /**
     * プリ画サービス利用規約へのURLを返す
     */
    termsPuriUrl = () => `/sp/common/information/terms_puri`;

    /**
     * 有料課金利用規約へのURLを返す
     */
    termsPaymentUrl = () => `/sp/common/information/terms_payment`;

    /**
     * 特定商取引法に基づく表示
     */
    tokuteiUrl = () => `/sp/common/information/tokutei`;

    /**
     * 個人情報保護方針へのURLを返す
     */
    privacyUrl = () => `https://www.furyu.jp/privacy.html`;

    /**
     * 健全化に資する運用方針へのURLを返す
     */
    operatingPolicyUrl = () => `/sp/common/information/operatingPolicy`

    /**
     * ネット決済事業者一覧及び特則へのURLを返す
     */
    paymentProviderUrl = () => `/sp/common/information/payment_provider`

    /**
     * 個人情報の開示等の請求についてのご案内へのURLを返す
     */
    privacyInfoUrl = () => 'https://www.furyu.jp/privacy/info.html';
    /**
     * Cookie等の利用についてへのURLを返す
     */
    privacyListUrl = () => 'https://www.furyu.jp/privacy_list.html';
}