/**
 * 本番ドメイン
 */
export default class ProductUrlUtil {

    /**
     * 本番のドメインを返す
     */
    productDomain = () => `https://sp.pictlink.com`;

    /**
     * furyu.jpのURL
     */
    furyuUrl = () => `https://www.furyu.jp`;

    /**
     * Mew contactのURL
     */
    mewcontactUrl = () => `https://contact.mewshop.jp`;

    /**
     * HARUHARUのURL
     */
    haru2Url = () => `https://haru2.jp`;

    /**
     * dメニューへのURL
     */
    dMenuUrl = () => 'https://smt.docomo.ne.jp/';
}