import ApiEndpointUrlUtil from "./urlUtil/ApiEndpointUrlUtil"
import HelpUrlUtil from './urlUtil/HelpUrlUtil'
import ImageUrlUtil from './urlUtil/ImageUrlUtil.js'
import PictlinkAppSchemeUrlUtil from './urlUtil/PictlinkAppSchemeUrlUtil'
import PolicyUrlUtil from './urlUtil/PolicyUrlUtil'
import ServletUrlUtil from './urlUtil/ServletUrlUtil'
import ProductUrlUtil from './urlUtil/ProductUrlUtil'
import MailAddUtil from './urlUtil/MailAddUtil'
import S3JsonUrlUtil from './urlUtil/S3JsonUrlUtil'
import WordPressUtil from './urlUtil/WordPressUtil'
import SnsUrlUtil from './urlUtil/SnsUrlUtil'
import IdentityVerifier from './urlUtil/IdentityVerifierUtil'
import AbsoluteUrlUtil from './urlUtil/AbsoluteUrlUtil'
import WithdrawalUrlUtil from "./urlUtil/WithdrawalUrlUtil";

export const apiEndpointUtil = new ApiEndpointUrlUtil();
export const helpUrlUtil = new HelpUrlUtil();
export const imageUrlUtil = new ImageUrlUtil();
export const pictlinkAppSchemeUrlUtil = new PictlinkAppSchemeUrlUtil();
export const policyUrlUtil = new PolicyUrlUtil();
export const servletUrlUtil = new ServletUrlUtil();
export const productUrlUtil = new ProductUrlUtil();
export const mailAddUtil = new MailAddUtil();
export const s3JsonUrlUtil = new S3JsonUrlUtil();
export const wordPressUtil = new WordPressUtil();
export const snsUrlUtil = new SnsUrlUtil();
export const identityVerifierUtil = new IdentityVerifier();
export const absoluteUrlUtil = new AbsoluteUrlUtil();

export const withdrawalUrlUtil = new WithdrawalUrlUtil();
