/**
 * ピクトリンクアプリに関するURLを組み立てるクラス.
 */
export default class PictlinkAppSchemeUrlUtil {

    /**
     * iOS: App storeへのリンク
     */
    appStoreUrl = () => `https://itunes.apple.com/jp/app/pictlink/id446747531`;

    /**
     * Android: Google Playストアへのリンク
     */
    googlePlayUrl = () => `https://play.google.com/store/apps/details?id=jp.furyu.pictlink`;

    /**
     * アプリを立ち上げるユニバーサルリンク.
     * iOS9以上またはAndroid6以上の端末で利用可能
     *
     * @param schemePath
     */
    appUniversalLink = (schemePath) => `https://launch.pictlink.com/sp/launch_app/ul/${schemePath}`;

    /**
     * iOSアプリを立ち上げるディープリンク.
     * iOS9未満の端末から呼ばれる
     *
     * @param schemePath
     */
    iOSDeepLink = (schemePath) => `pictlink://${schemePath}`;

    /**
     * Androidアプリを立ち上げるディープリンク.
     * Android6未満の端末から呼ばれる
     *
     * @param schemePath
     */
    androidDeepLink = (schemePath) => `intent://${schemePath}#Intent;scheme=pictlink;package=jp.furyu.pictlink;end`;
}