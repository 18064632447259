import configs from 'configs';

export default class IdentityVerifierUtil {

    /**
     * IdentityVerifierのAPIエンドポイントを組み立てる
     * @param {string} apiPath apiのパス
     */
    identityVerifierApiUrl = (apiPath) => `${configs.identityVerifierPath}/${apiPath}`;

    /**
     * オーディション応募用メールを送信するAPIエンドポイントを組み立てる
     * @param {number}  auditionsId
     */
    sendAuditionsMailApiUrl = (auditionsId) => this.identityVerifierApiUrl(`api/v1/users/self/auditions/${auditionsId}/sessions`);

    /**
     * 本人確認申込開始APIで発行されたセッションが有効かどうか確認するAPIエンドポイントを組み立てる
     * @param {number} auditionId
     * @param {string} sessionId
     */
    auditionSessionApiUrl = (auditionId, sessionId) => this.identityVerifierApiUrl(`api/v1/users/self/auditions/${auditionId}/sessions/${sessionId}`)

    /**
     * 本人確認に申し込み可能かどうか確認するAPIエンドポイントを組み立てる
     * @param {number} auditionId
     */
    auditionAvailableApiUrl = (auditionId) => this.identityVerifierApiUrl(`api/v1/users/self/auditions/${auditionId}/available`)

    /**
     * 本人確認の申し込みを完了するAPIエンドポイントを組み立てる
     * @param {number} auditionId
     * https://identity-verifier-docs.s3.ap-northeast-1.amazonaws.com/receptor/redoc.v2.html#tag/user/operation/post-users-self-auditions
     */
    completedAuditionApiUrl = (auditionId) => this.identityVerifierApiUrl(`api/v2/users/self/auditions/${auditionId}`)
}