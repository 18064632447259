import configs from 'configs';

/**
 * S3管理のjsonURLを組み立てるクラス.
 */
export default class S3JsonUrlUtil {
    /**
     * jsonのパス
     * S3管理
     * ブラウザキャッシュを防ぐためタイムスタンプを追加している。cloudfrontのキャッシュには影響なし。
     * @param {string} jsonName ファイル名
     */

    S3JsonUrl = (jsonName) => {
        const date = new Date() ;
        const timestamp = date.getTime() ;
        return `${configs.cloudFrontPath}/data-source/${jsonName}.json?timestamp=${timestamp}`;
    }

    S3HelpJsonUrl = (jsonName) => {
        const date = new Date() ;
        const timestamp = date.getTime() ;
        return `${configs.cloudFrontPath}/help/data-source/${jsonName}.json?timestamp=${timestamp}`;
    }
}