
import AbsoluteUrlUtil from "./AbsoluteUrlUtil";

/**
 * バックエンドでurl mappingしているページのURLを組み立てるクラス
 */
export default class ServletUrlUtil extends AbsoluteUrlUtil {
    /**
     * ベース課金ページへのURLを返す.
     */
    paymentBaseUrl = () => "/sp/payment";

    /**
     * ベース課金ページのプラス会員へのURLを返す.
     */
    paymentPlusBaseUrl = () => "/sp/payment/#plus";

    /**
     * アドオン課金ページへのURLを返す.
     */
    paymentAddOnUrl = () => "/sp/2/payment/plus/add_on";

    /**
     * 有料会員解約ページへのURLを返す.
     */
    paymentCancelUrl = () => "/sp/payment/cancel"

    /**
     * 無料会員登録ページへのURLを返す.
     */
    registrationUrl = () => "/sp/account/carrier_auth";

    /**
     * 無料会員登録のメールアドレス登録ページへのURLを返す.
     */
    registrationEmailUrl = () => "/sp/account/register_email";

    /**
     * 無料会員登録のメールアドレス登録メール送信完了ページへのURLを返す.
     */
    sentRegistrationEmailUrl = () => "/sp/account/registerMailSent";

    /**
     * 直接入会課金ページへのURLを返す.
     */
    directPaymentUrl = () => {
        return "/sp/2/direct_payment"
    };

    /**
     * メアド・パスワード新規登録ページへのURLを返す.
     */
    registerEmailUrl = () => {
        return "/sp/2/register_email"
    };

    /**
     * マイページへのURLを返す.
     */
    myPageUrl = () => "/sp/2/mypage/#/puri/new";

    /**
     * プリ詳細ページへのurlを組み立てる
     *
     * @param {Number} imageId     プリのimageId
     * @param {String} category    puri / raw
     * @param {String} order       new / date / machine
     * @param {Number} [machineId] orderをmachineに指定した場合に必須 絞り込む筐体ID
     * @param {String} [savedAt]   orderをdateに指定した場合に必須 絞り込む保存日(yyyy-MM-dd)
     */
    myPagePuriDetailUrl = (imageId, category, order, machineId = null, savedAt = null) => {
        let url = `/sp/2/mypage/images/${imageId}/detail?type=${category}&order=${order}`;

        if(machineId) {
            url += (`&machineId=${machineId}`);
        }
        if(savedAt) {
            url += (`&savedAt=${savedAt}`);
        }
        return url;
    };

    /**
     * プリGETページのurl
     * ゲスト、もしくはミニ&証明プリの場合はLPへ
     * それ以外はプリGET完了へ
     * @param  {Membership} membership accountStatusに含まれるmembership
     * @param  {String} sealId シールId
     * @param  {Boolean} isProof 証明プリかどうか
     */
    puriGetUrl = (membership, sealId, isProof) => {
        if(membership.isGuest || (membership.isMini && isProof)) {
            return `/sp/puri/get?sealId=${sealId}`
        } else {
           return  `/sp/puri/check_openid_grid?sealId=${sealId}`
        }
    };

    /**
     * GET完了画面へのURL
     */
    puriGetCompleteUrl = (sealId) => {
        if (!sealId) {
            return null
        }

        return `/sp/puri/check_grid?sealId=${sealId}`
    }

    /**
     * 削除したプリを再度保存させるページのurlを組み立てる
     * (ただのプリGETページだが削除関係のものを依存させたくないのであえて名前をつけている)
     *
     * @param {number} sealId 削除プリに紐づくsealId
     */
    resavePuriUrl = (sealId) => `/sp/puri/grid?sealId=${sealId}`;

    /**
     * 設定ページのURL
     */
    settingUrl = () => "/sp/setting/userinfo";

    /**
     * クーポン一覧ページのURL
     */
    couponUrl = () => "/sp/2/coupon/tickets";

    /**
     * プリ機の特徴からさがすページへのURLを返す.
     */
    machineSearchUrl = () => `/sp/2/machines/search`;

    /**
     * ペナルティについての説明ページへのURLを返す.
     */
    penaltyDetailUrl = () => `/sp/common/aboutPenalty`;

    /**
     * ログインページへのURLを返す.
     */
    loginUrl = () => `/sp/login/top`;

    /**
     * ピクトリンクお問い合わせページへのURLを返す.
     */
    inquiryUrl = () => `/sp/common/inquiry`;

    /**
     * ピクトリンクお問い合わせの指定カテゴリーの入力フォームページへのURLを返す.
     * @param category {string}
     */
    inquiryInputUrl = (category) => `/sp/common/inquiry#/input/` + category

    /**
     * プリお問い合わせページへのURLを返す.
     */
    inquiryPuriUrl = () => `/sp/common/inquiry_puri`;

    /**
     * ピクトリンクフォトお問い合わせページへのURLを返す.
     */
    pictlinkPhotosInquiryUrl = () => "/sp/common/inquiry#/pictlinkPhotos/input";

    /**
     * (ログイン時の)トップページへのURLを返す.
     */
    topUrl = () => `/sp/top/menu`;

    /**
     * MINIからSTANDARDになるときのキャリア等の課金ページへのURL
     * @param paymentKind {string}
     */
    paymentUrlForMiniToStandard = (paymentKind) => {
        switch (paymentKind) {
            case 'ezweb':
                return '/sp/auoneadmit/admittop';
            case 'docomo':
                return '/sp/2/docomo_payment/subscribe/top';
            case 'softbank':
                return '/sp/softbankadmit/admittop';
            case 'credit':
                return '/sp/2/payment/veritrans/credit/';
            default:
                return null;
        }
    };

    /**
     * MINIからお試し登録するときのauお試し登録処理開始URL
     */
    paymentTrialStartUrlForMiniToStandard = () => '/sp/auoneadmit/au_trial_start';

    /**
     * MINIからお試し登録するときのau課金TOPページへのURL
     */
    paymentTrialAdmitTopUrlForMiniToStandard = () => '/sp/auone/trialAdmitTop';

    /**
     * お試し登録ページへのURL
     * @param canTrial
     * @param network
     * @param from
     */
    paymentTrialUrl = (canTrial, network, from) => `/sp/2/payment/trial/top?from=${from}#/${canTrial?"permitted":"excluded"}/${network}`;

    /**
     * ログインしていない人向けの有料会員解約案内ページへのURL
     * 通常の有料会員解約案内ページとは異なる
     */
    withdrawalForGuestUrl = () => `/sp/paymentid_withdrawal/top`;

    /**
     * 筐体一覧へのURL
     */
    machineListUrl = (isIdFederationSupported) => `/sp/machines/information${isIdFederationSupported ? '?isIdFederationSupported=true' : ''}`;

    /**
     * 筐体紹介ページへのURL
     */
    machinePagetUrl = (id) => {
        return `/sp/machine/${id}`
    };

    /**
     * LINE連携ページへのURL
     * @param {string} afterCancelPath LINEアプリ上で連携に同意しなかった場合の遷移先URL
     * @param {string} afterLinkPath   LINE連携完了後ピクトリンクに戻ってきた場合の遷移先URL
     */
    lineRecommendationUrl = (afterCancelPath, afterLinkPath) => `/sp/2/link/line/pictlinkauth?acp=${afterCancelPath}&alp=${afterLinkPath}`;

    /**
     * LINEアカウント更新ページへのURL
     * @param {string} afterCancelPath LINEアプリ上でアカウント更新に同意しなかった場合の遷移先URL
     * @param {string} afterLinkPath   LINEアカウント更新完了後ピクトリンクに戻ってきた場合の遷移先URL
     */
    lineReplaceUrl = (afterCancelPath, afterLinkPath) => `/sp/2/link/line/switch_start/pictlinkauth?acp=${afterCancelPath}&alp=${afterLinkPath}`;

    /**
     * LINE連携スキップのURL
     * @param {string} afterCancelPath
     */
    skipLineRecommendationUrl = (afterCancelPath) => `/sp/2/link/line/skip_recommendation?rp=${afterCancelPath}`;

    /**
     * DOCOMOの認証URL
     */
    docomoAuthUrl = (onSuccessUrl, onFailureUrl) => {
        const onSuccessAbsoluteUrl = this.buildPictlinkAbsoluteUrl(onSuccessUrl);
        const onFailureAbsoluteUrl =this.buildPictlinkAbsoluteUrl(onFailureUrl);
        return `${this.buildPictlinkAuth('/api/v1/authn/docomo')}?onSuccess=${onSuccessAbsoluteUrl}&onFailure=${onFailureAbsoluteUrl}`;
     }

    /**
     * auの認証URL
     */
    auAuthUrl = (onSuccessUrl, onFailureUrl) => {
        const onSuccessAbsoluteUrl = this.buildPictlinkAbsoluteUrl(onSuccessUrl);
        const onFailureAbsoluteUrl =this.buildPictlinkAbsoluteUrl(onFailureUrl);
        return `${this.buildPictlinkAuth('/api/v1/authn/au/auoneid')}?onSuccess=${onSuccessAbsoluteUrl}&onFailure=${onFailureAbsoluteUrl}`;
    }

    /**
     * softBankの認証URL
     */
    softBankAuthUrl = (onSuccessUrl, onFailureUrl) => {
        const onSuccessAbsoluteUrl = this.buildPictlinkAbsoluteUrl(onSuccessUrl);
        const onFailureAbsoluteUrl =this.buildPictlinkAbsoluteUrl(onFailureUrl);
        return `${this.buildPictlinkAuth('/api/v1/authn/softbank')}?onSuccess=${onSuccessAbsoluteUrl}&onFailure=${onFailureAbsoluteUrl}`;
    }

    /**
     * LINEログインのベースURL
     */
    lineLoginBaseUrl = () => {
        return '/sp/2/login/line/pictlinkauth'
    }

    /**
     * LINEの認証URL
     */
    lineAuthUrl = (onSuccessPath, onFailurePath, redirectUri) => {
        const onSuccessBase = this._appendRedirectUri(onSuccessPath, redirectUri)
        const onSuccessAbsoluteUrl = this.buildPictlinkAbsoluteUrl(onSuccessBase);
        const onFailureAbsoluteUrl =this.buildPictlinkAbsoluteUrl(onFailurePath);
        return `${this.buildPictlinkAuth('/api/v1/authn/line')}?onSuccess=${onSuccessAbsoluteUrl}&onFailure=${onFailureAbsoluteUrl}`;
    }

    /**
     * LINEで新規登録のURL[pictlink]
     */
    lineRegistrationUrlPictlink = (redirectUri) => {
        return this.lineAuthUrl(
            '/sp/2/account/registration/line/fetch_userinfo',
            '/sp/2/direct_payment#/error/auth/line/failed',
            redirectUri
            );
    }

    /**
     * LINEで新規登録のURL
     */
    lineRegistrationUrl = (onFailurePath, afterRegisterUri) => {
        const onSuccessBase = this._appendRedirectUri('/sp/2/account/registration/line/pictlinkauth', afterRegisterUri);
        const onSuccess = this.buildPictlinkAbsoluteUrl(onSuccessBase)
        const onFailure = this.buildPictlinkAbsoluteUrl(onFailurePath);
        return `${this.buildPictlinkAuth('/api/v1/authn/line')}?onSuccess=${onSuccess}&onFailure=${onFailure}`;
    }

    /**
     * @private
     * @param path redirectUriを付与するPath。独自のquery paramがあってもOK. e.g. /sp/account/login, /sp/puri/get?sealId=xxx
     * @param redirectUri pathに付与されるredirectUri.
     */
    _appendRedirectUri = (path, redirectUri) => {
        if (!redirectUri) {
            return path;
        }
        const url = new URL(path, location.origin);
        const searchParams = url.searchParams;
        searchParams.set("redirectUri", redirectUri);
        return `${url.pathname}${url.search}`
    }



    /**
     * twitterでログインのURL
     */
    twitterLoginUrl = () => {
        const onSuccess = this.buildPictlinkAbsoluteUrl('/sp/2/login/twitter/pictlinkauth');
        const onFailure =this.buildPictlinkAbsoluteUrl('/sp/login/fail');
        return `${this.buildPictlinkAuth('/api/v1/authn/twitter')}?onSuccess=${onSuccess}&onFailure=${onFailure}`;
    }

    /**
     * ログイン方法ページへのURL
     */
    loginInformationUrl = () => `/sp/common/information/login`;

    /**
     * ログインパスワード変更ページへのURL
     */
    loginPasswordChangeUrl = () => `/sp/password/change`;

    /**
     * [中学生ピクトリンク無料サービス]申込情報入力ページへのURL
     */
    inputProfileUrl = () => `/sp/jhs_free#/input/profile`;

    /**
     * [中学生ピクトリンク無料サービス]LPのURL
     */
    jhsFreeLpUrl = (parameter) => `/sp/jhs_free?from=${parameter}#/lp`

    /**
     * プリニュースのURL
     * @param id {String} WordPressの記事ID
     * @param parameter {String} fromパラメーター.
     */
    puriNewsUrl = (id, parameter) => `/sp/puri_news?from=${parameter}#/${id}`

    /**
     * 「遊べるお店はこちら」ページのURL
     * @param machineName {String} 筐体名
     */
    shopSearchUrl = (machineName) =>  `https://map.pictlink.com/?machine=${machineName}`
    /**
     * ユーザ情報削除のURL
     * @return {string}
     */
    accountDelete = () => '/sp/account/delete'

    veritransPremiumWithdrawalUrl = () => '/sp/2/payment/veritrans/credit/premium/withdraw'
    docomoPaymentPremiumWithdrawalUrl = () =>'/sp/2/docomo_payment/downgrade/start'

    auPaymentPremiumWithdrawalUrl = () =>'/sp/2/payment/au/addon/withdrawal'
    auPaymentStandardWithdrawalUrl = () =>'/sp/auonewithdrawal/withdrawal'
    auPaymentShiftStandardWithdrawalUrl = () =>'/sp/auonewithdrawal/shiftWithdrawal'

    spmodePremiumWithdrawalUrl = () =>'/sp/spmodeclient/addon_withdrawal'

    spmodeStandardWithdrawalUrl = () =>'/sp/spmodeclient/normal_withdrawal'
    spmodeShiftStandardWithdrawalUrl = () =>'/sp/spmodeclient/shift_withdrawal'

    veritransStandardWithdrawalUrl = () =>'/sp/2/payment/veritrans/credit/withdraw'
    docomoPaymentStandardWithdrawalUrl = () =>'/sp/2/docomo_payment/unsubscribe/start'}
