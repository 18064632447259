/**
 * ヘルプページのURLを組み立てるクラス
 */
export default class HelpUrlUtil {

    /**
     * ヘルプページTOPへのURL
     * @param {string} helpPageKind ヘルプページの種類
     */
    helpTopUrl = (helpPageKind) => `/sp/common/help#/top/${helpPageKind}`;

    /**
     * ヘルプ詳細ページへのURLを返す.
     */
    helpDetailUrl = (helpId) => `/sp/common/help#/answer/${helpId}`;

}